export const lang = {
  payment_list: 'Liste des réglements',
  payment: 'Réglement ',
  payment_header_number: 'Réglement N°',
  payment_number: 'N°.Réglement',
  deadline: 'Date d\'échéance',
  bank: 'Banque',
  ref: 'Ref',
  received_amount: 'Montant encaissé',
  init_amount: 'Montant initial',
  consumed_amount: 'Montant consommé',
  remaining_amount: 'Reliquat',
  be_paid_document:'Documents à régler',
  payment_lines:'Lignes réglement',
  encasement_amount:"Montant encaissé",
  total_amount:"Montant",
  paid_doc:"N°. Document",
  line_remaining_amount:"Reste à régler",
  payment_outstanding_amount:"Reliquat",
  header_invalidate:"Dévalider l'en-tête",
  calculate:"Calculer",
  dissociate:"Dissocier",
  external_doc_id:"N°.Document HIS/RIS"

};
