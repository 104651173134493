import {Component, OnInit} from '@angular/core';
import {HEIGHT_ANIMATION, SCALE_ANIMATION} from '@ft/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import * as _ from 'lodash';
import {StateService} from '../../services/state-service.service';
import {TableColumnDefinition} from '@ft/table';
import {LINES_COLUMNS} from '../../../shared/utils/lines-headers';
import {PHYSICIAN_VIEW} from '../../../shared/utils/views-settings';
import * as moment from 'moment';
import {TableColumnType} from '@ft/table/table/models/table-definitions';
import {saveAs} from 'file-saver';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ftb-state-view',
  templateUrl: './state-view.component.html',
  animations: [
    SCALE_ANIMATION,
    HEIGHT_ANIMATION
  ],
  styleUrls: ['./state-view.component.scss']
})
export class StateViewComponent implements OnInit {
  items: any = [];
  busy: Subscription;

  percentModel = {center_percent: 100, physician_percent: 0, base_percent: 'taxed_amount', show_percents: false};
  showPercents = false;

  linesSource$: Observable<any[]> = of([]);
  linesColumns: BehaviorSubject<TableColumnDefinition[]>;
  filter = {'physician': {}, 'from_date': moment(), 'to_date': moment()};
  physicianInputSettings = _.assignIn(_.cloneDeep(PHYSICIAN_VIEW), {selectable: false});
  has_res = false;
  viewColumns = [];

  constructor(private stateService: StateService, private translateService: TranslateService) {
  }

  ngOnInit() {
    this.handleFilterChange(null);
  }

  trackByFn(index, item) {
    return _.get(item, 'label', index);
  }

  reportClick(link) {

  }

  onPhysicianChange = (data) => {

    this.filter.physician = data;
    this.handleFilterChange(null);

  };

  handleFilterChange(ev) {
    this.busy= this.stateService.getState(this.formatFilter()).subscribe(data => {
      let totals = _.get(data, 'totals', {});
      if (!_.isNil(data)) {
        this.has_res = !_.isEmpty(_.get(data, 'data'));
        let columns: TableColumnDefinition[] = _.chain(_.get(data, 'columns')).map((e) => {
          let _type: TableColumnType = e == 'patients' ? 'text' : 'number';
          return {
            label: `states.${e}`,
            type: _type,
            key: e,
            numberConfig: 'float',
            footer: _.get(totals, e)
          };
        }).toArray().value();
        this.viewColumns = columns;
        this.linesColumns = new BehaviorSubject<TableColumnDefinition[]>(columns);
        this.linesSource$ = of(_.get(data, 'data', []));
      } else {
        this.has_res = false;
      }
    });

  }

  formatFilter() {
    return _.assign({
      _from: _.get(this.filter, 'from_date').format('DD/MM/YYYY'),
      _to: _.get(this.filter, 'to_date').format('DD/MM/YYYY'),
      _physician: _.get(this.filter, 'physician.id')
    }, this.percentModel.show_percents ? this.percentModel : {})
  }

  handlePercentChange(p) {
    switch (p) {
      case 'physician_percent':
        this.percentModel.center_percent = 100 - this.percentModel.physician_percent;
        break;
      case 'center_percent':
        this.percentModel.physician_percent = 100 - this.percentModel.center_percent;
        break;
    }
    this.handleFilterChange(null);
  }

}
