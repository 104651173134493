import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import * as _ from 'lodash';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';
import {BillingService} from '../../../shared/services/billing.service';
import {PaymentService} from '../../services/payment.service';
import {DeleteDialogComponent} from '../../../shared/dialogs/delete-dialog/delete-dialog.component';
import {SMALL_DIALOG} from '@ft/core';
import {getTopic} from '../../../shared/utils/functions';
import {AlertDialogComponent} from '../../../settings/dialogs/alert-dialog/alert-dialog.component';
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'ftb-payment-actions',
  templateUrl: './payment-actions.component.html',
  styleUrls: ['./payment-actions.component.scss']
})
export class PaymentActionsComponent implements OnInit {
  preLines = [];
  busy: Subscription = null;
  @Input() model;

  @Input('preLines') set handlePreLines(value: any) {
    this.preLines = value;
  };

  @Input() cls;
  @Input() headerDisabled;
  @Input() loadedObject;

  @Input() routes;
  @Output() modelChange = new EventEmitter();

  private _formInvalid = new BehaviorSubject(false);

  @Input('busy') set handleSubscription(value) {
    this.busy = value;
  }

  @Output() busyChange = new EventEmitter();


  @Input() set formInvalid(value: boolean) {
    this._formInvalid.next(value);
  }

  get formInvalid() {
    return this._formInvalid.getValue();
  }


  constructor(private  billingService: BillingService, private router: Router, private paymentService: PaymentService, protected dialog: MatDialog) {
  }

  ngOnInit() {
  }


  handlePaymentLine(row) {
    let paid_idx = _.findIndex(this.model.lines, function (item) {
      if (item['paid_doc'].id == row.paid_doc.id && item['paid_doc']._module == row.paid_doc._module && item['paid_doc']._model == row.paid_doc._model) return item;
    });

    if (paid_idx == -1) {
      this.model.lines.push(row);
    }
    else {
      _.update(this.model.lines, `[${paid_idx}]`, function (line) {
        return _.assignIn(line, {
          encasement_amount: line.encasement_amount + row.encasement_amount,
          remaining_amount: line.remaining_amount - row.encasement_amount
        });
      });
    }
    // row.encasement_amount=0;
  }

  save(with_exit?: Boolean) {
    _.chain(this.preLines).filter((l) => {
      return l['encasement_amount'] > 0;
    }).value().forEach(this.handlePaymentLine.bind(this));
    if (!_.isNil(this.loadedObject)) {
    this.billingService.loadDocument(this.cls, this.loadedObject).subscribe(data => {
      this.performSave(with_exit,data)
    })}
    else {
      this.performSave(with_exit);
    }
  }
  performSave(with_exit, load_data?){
    this.busy =this.billingService.saveBillingDoc(this.model, this.cls,load_data ? this.loadedObject : null).subscribe(data => data ? this.saveSuccess(data, with_exit) : _.noop)
    this.busyChange.emit(this.busy);
  }

  saveSuccess(data, with_exit) {
    this.model = data;
    this.handleSaveEvents();

    if (with_exit) {
      this.router.navigate([this.routes['list']]);
    } else {
      this.router.navigate([this.routes['form'], this.model.id]);
      this.modelChange.emit(data);
    }
  }


  handleSaveEvents() {
    _.chain(this.preLines).filter((l) => {
      return l.encasement_amount > 0;
    }).value().forEach((line) => {
      line.encasement_amount = 0;
    });

    // let notificationItems = _.chain(this.model.lines).filter((l) => {
    //   return l.paid_doc._module == 'request_flow.models' && l.paid_doc._model == 'BillingRequest';
    // }).map('paid_doc.id').value();

    this.paymentService.handleNotifications( _.get(this.model, 'id')).subscribe((data) => _.noop());
  }

  remove() {
    this.dialog.open(DeleteDialogComponent, _.assign(SMALL_DIALOG, {
      data: {obj: this.model, message_text: 'settings.delete_object_warning', deleteTopic: `${getTopic(this.cls)}.check_and_delete`}
    })).afterClosed().subscribe(res => {
      if (res) {
        this.router.navigate([this.routes['list']]);
      }
      else if (res == false) {
        this.dialog.open(AlertDialogComponent, _.assign(SMALL_DIALOG, {data: {message_text: 'settings.delete_object_failed'}}));
      }
    });
  }

  exit() {
    this.router.navigate([this.routes['list']]);
  }


  print() {
  }

  invalidateHeader() {
    this.modelChange.emit('headerInvalidate');
  }

  shareReceivedAmount() {
    this.modelChange.emit('shareReceivedAmount');

  }

  dissociateLines() {
    this.modelChange.emit('dissociateLines');
  }
}
