import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BillingService} from '../../services/billing.service';
import * as _ from 'lodash';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import {HEIGHT_ANIMATION, SCALE_ANIMATION, SMALL_DIALOG} from '@ft/core';
import {getTopic, getUrl} from '../../utils/functions';
import {AlertDialogComponent} from '../../../settings/dialogs/alert-dialog/alert-dialog.component';
import {MatDialog} from "@angular/material/dialog";
import {DeleteDialogComponent} from '../../dialogs/delete-dialog/delete-dialog.component';
import {delay} from 'rxjs/operators';

@Component({
  selector: 'ftb-document-actions',
  templateUrl: './document-actions.component.html', animations: [
    SCALE_ANIMATION,
    HEIGHT_ANIMATION
  ],
  styleUrls: ['./document-actions.component.scss']
})
export class DocumentActionsComponent implements OnInit {
  @Input() model;
  @Input() loadedObject;
  @Input() cls;
  @Input() routes;
  @Input() printGroup;
  @Output() modelChange = new EventEmitter();
  @Input() saveEmpty: boolean = false;


  private _formInvalid = new BehaviorSubject(false);
  preventSave = false;
  busy: Subscription = null;
  printModel: number;

  @Input() set formInvalid(value: boolean) {
    this._formInvalid.next(value);
  }

  get formInvalid() {
    return this._formInvalid.getValue();
  }


  @Input('busy') set handleSubscription(value) {
    this.busy = value;
  }

  @Output() busyChange = new EventEmitter();


  constructor(private  billingService: BillingService, private router: Router, private route: ActivatedRoute, protected dialog: MatDialog) {
  }

  ngOnInit() {
  }

  save(with_exit?: Boolean) {
    if (!this.preventSave) {
      this.preventSave = true;
      if (!_.isNil(this.loadedObject)) {
        this.billingService.loadDocument(this.cls, this.loadedObject).subscribe(data => {
          this.handleSave(with_exit, data);
        });
      }
      else {
        this.handleSave(with_exit);
      }
    }
  }

  handleSave(with_exit, load_data?) {
    this.busy = this.billingService.saveBillingDoc(this.model, this.cls, load_data ? this.loadedObject : null).subscribe(data => {
      if (data) {
        this.saveSuccess(data, with_exit);
        this.preventSave = false;
      }
    }, error => {
      this.preventSave = false;
    });
    this.busyChange.emit(this.busy);
  }

  saveSuccess(data, with_exit) {
    this.model = data;
    if (with_exit) {
      this.router.navigate([this.routes['list']]);
    } else {
      this.router.navigate([this.routes['form'], this.model.id], {replaceUrl: true});
      this.modelChange.emit(data);
    }

  }

  remove() {
    this.dialog.open(DeleteDialogComponent, _.assign(SMALL_DIALOG, {
      data: {
        obj: this.model,
        message_text: 'settings.delete_object_warning',
        deleteTopic: `${getTopic(this.cls)}.check_and_delete`
      }
    })).afterClosed().subscribe(res => {
      if (res) {
        this.router.navigate([this.routes['list']]);
      }
      else if (res == false) {
        this.dialog.open(AlertDialogComponent, _.assign(SMALL_DIALOG, {data: {message_text: 'settings.delete_object_failed'}}));
      }
    });
  }

  exit() {
    this.router.navigate([this.routes['list']]);
  }

  invalidate() {
    if (_.get(this.model.state, 'is_valid')) {
      this.billingService.allowInvalidate(this.model.id, this.cls).subscribe(res => {
        if (res) {
          this.busy = this.billingService.invalidateBillingDoc(this.model.id, this.cls).subscribe(data => {
            this.model = data;
            this.modelChange.emit(data);
          });
          this.busyChange.emit(this.busy);
        }
        else {
          this.dialog.open(AlertDialogComponent, _.assign(SMALL_DIALOG, {data: {message_text: 'shared.invalidate_object_failed'}}));
        }
      });
    }
  }

  validate() {
    if (!_.get(this.model, 'state.is_valid', false)) {
      if (!this.preventSave) {
        this.preventSave = true;
        if (!_.isNil(this.loadedObject)) {
          this.billingService.loadDocument(this.cls, this.loadedObject).subscribe(data => {
            this.performValidate(data);
          });
        }
        else {
          this.performValidate();
        }
      }
    }
  }

  performValidate(load_data?) {
    this.billingService.saveBillingDoc(this.model, this.cls, load_data ? this.loadedObject : null).subscribe(data => {
      if (data) {
        this.model = data;
        this.handleValidate();
      }
    });
  }

  handleValidate() {
    this.busy = this.billingService.validateBillingDoc(this.model, this.cls).subscribe(data => {
      this.modelChange.emit(data);
      this.preventSave = false;
      this.saveSuccess(data, false);
    });
    this.busyChange.emit(this.busy);
  }

  prePrint() {
  }

  getPrintUrl() {
    return getUrl(this.cls);
  }

}
