export const lang = {
  invalidate: 'Invalidate',
  validate: 'Validate',
  exit: 'Exit',
  save_exit: 'Save and exit',
  save: 'Save',
  date: 'Date',
  patient: 'Patient',
  PATIENT: 'PATIENT',
  patient_list: 'Patients List',
  physician_list: 'Doctors List',
  organization: 'Organization',
  beneficiary: 'Beneficiary',
  beneficiary_type: 'Beneficiary',
  physician: 'Doctor',
  comment: 'Comment',
  quotation: 'Quote',
  b_procedures: 'Procedures',
  store_articles: 'Pharmacy',
  patient_visits: 'HIS/RIS',
  fees_notes: 'Fee notes',
  code: 'Code',
  description: 'Name',
  qte: 'Qty',
  price: 'Price',
  tm_percent: 'TM%',
  tp_percent: 'TP%',
  tm_amount: 'TM amount',
  tp_amount: 'TP Amount',
  sub_amount: 'Subtotal',
  organization_part: 'Organization Part',
  patient_part: 'Adherent share',
  total: 'Total',
  new: 'New',
  reset: 'Cancel',
  confirm: 'Confirm',
  ok: 'OK',
  email: 'Email',
  name: 'Name',
  full_name: 'Full Name',
  address: 'Address',
  phone_number: 'Telephone',
  invalid_value: 'Invalid value',
  is_required: 'This field is required',
  submit_add: 'Add',
  new_line: 'New line',
  invoice_select_details: 'LIST OF CHARGES',
  first_name: 'First name',
  last_name: 'Name',
  payer: 'Payer',
  other: 'Other',
  cin: 'NIC',
  type: 'Type',
  value: 'Value',
  procedure: 'Procedure',
  convention: 'Convention',
  CONVENTION: 'Convention',
  valid_date: 'Valid until',
  taxed_amount: 'TTC amount',
  discount: 'Discount(%)',
  days_suffix: 'day(s)',
  patientVisits: 'Visit',
  feesNotes: 'Fees note',
  quotations: 'Quote',
  nr: 'N°',
  insurance: 'Insurance',
  care_sheets: 'Mutual',
  insured_type: 'Insured',
  title: 'Title',
  birth_date: 'Birthdate',
  details: 'Details',
  custom: 'Personalized',
  add_line: 'Add a line',
  add_comment: 'add a comment',
  already_exist: 'already exists',
  copyright: 'Copyright 2013-2017 - dépôt légal N°2017LB0009',
  modality: 'Modality',
  pec_answer_nature: 'PEC Answers',
  'net_10_days': '10 days net',
  'net_30_days': '30 days net 30',
  'net_120_days': '120 net days',
  'net_60_days': '60 days net',
  'net_90_days': '90 days net',
  'end_of_month_the_10': 'End of the month on the 10th',
  'end_of_month_the_25': 'End of the month on the 25th',
  '30_end_of_month': '30 days end of month',
  '45_end_of_month': '45 days end of month',
  '60_end_of_month': '60 days end of the month',
  '90_end_of_month': '90 days end of the month',
  '30_end_of_month_the_10': '30 days end of the month on the 10th',
  '30_end_of_month_the_15': '30 days end of the month on the 15th',
  '30_end_of_month_the_25': '30 days end of the month on the 25th',
  '60_end_of_month_the_10': '60 days end of the month on the 10th',
  '60_end_of_month_the_25': '60 days end of the month on 25',
  '90_end_of_month_the_10': '90 days end of the month on the 10th',
  payment_condition: 'Payment condition',
  select_conventions: 'Select conventions',
  add_new_line: 'New line',

  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'may',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
  payment_mode: 'Payment method',
  'None': 'Any',
  'CASH': 'Cash',
  'CCCA': 'Credit card',
  'CCHK': 'Check',
  'CDAC': 'Credit / Debit Account',
  'CHCK': 'Check',
  'DDPO': 'Direct deposit',
  'DEBC': 'Debit card',
  invalidate_object_failed: 'Can not devalid this document Please check out its dependencies!',
  from_date: 'From',
  to_date: 'To',
  patient_first_name: 'Patient. First name',
  patient_last_name: 'Patient. Last Name',
  "stop_document_at_amount": 'Approved this document for the sum of',
  "upload_file_error": 'Upload error',
  "upload_success": 'Upload finished',
  add: 'Add',
  gender: 'Sex',
  payment_status: 'Payment status',
  default_toolbar_tab: 'Default tab',
  global_discount: 'Global discount',
  amount: 'Total amount',
  bill: "Bill",
  pay: 'Pay',
  delete: 'Remove',
  print: 'Print',
  edit: "Modify",
  discount_amount: 'Discount',
  discount_percent: 'Discount (%)',
  financial_details: 'Payment details',
  today: 'Today',
  three_days: '3 days',
  this_week: '1 week',
  this_month: '1 month',
  all: 'All',
  hl_seven_connection_failed: 'HL7 connection Lost!!!',
  THIRD_PARTY_PAYMENT: 'Third-party payment',
  new_exam: 'New exam',
  pec: 'Medical Claims',
  PEC: 'PEC',
  patient_already_exist: 'Patient already exists',
  close: 'Close',
  exam_file: 'Exam file',
  view: 'Visualize',
  INSURED_PATIENT: 'Insured',
  unpaid: "Unpaid",
  paid: 'paid',
  partial: 'partial payment',
  exempt: 'exempted',
  in_progress: 'In progress',
  granted: 'Granted',
  rejected: 'Rejected',
  agreement_state: 'Folder status',
  state: 'State',
  color: 'Color',
  save_empty_document: 'Save empty record',
  is_valid: 'Valid',
  is_draft: 'Draft',
  base_percent: 'Calculation base',
  stock_module: 'Pharmacy',
  stock: 'Stock',
  external_technician_code: 'N ° .INAMI - Dispenser Name',
  external_physician_code: 'N ° .INAMI - Prescriber Name',
  doc_date: 'Date. Document',
  apply_convention: 'Reapply convention'
};
