import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BillingService} from '../../services/billing.service';
import {DefaultValuesService, FtWsService, MEDIUM_DIALOG, PrintingService, SMALL_DIALOG} from '@ft/core';
import {MatDialog } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {AlertDialogComponent} from '../../../settings/dialogs/alert-dialog/alert-dialog.component';
import {DeleteDialogComponent} from '../../dialogs/delete-dialog/delete-dialog.component';
import {RouterDataService} from '../../services/router-data.service';
import {DailyWorkflowService} from '../../../daily-workflow/services/daily-workflow.service';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {getUrl} from "../../utils/functions";
import * as moment from "moment";
import {TableQuery} from "@ft/table/table/models/table-definitions";
import {DEFAULT_FILTERS} from "../../utils/default-conts";
import {PaymentService} from "../../../payment/services/payment.service";
import {SharedService} from "../../services/shared-service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'ftb-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss']
})
export class DocumentsListComponent implements OnInit, OnDestroy {
  reloadEvent = new EventEmitter();
  settings = null;
  viewSettings = null;

  subscription: Subscription;

  importCallBack = () => {
    return this.reloadEvent.emit(true);
  };
  actions = [];
  defaultFilters = DEFAULT_FILTERS;

  initialQuery: TableQuery[] = [
    {
      value: moment().format('YYYY-MM-DDT00:00:00.000') + 'Z',
      operator: '$eq',
      logical_operator: 'and',
      key: 'created_at',
      type: 'date'
    }
  ];
  footerHandler = (value) => { }

  constructor(protected route: ActivatedRoute, protected router: Router,
              protected dialog: MatDialog, private translateService: TranslateService, protected sharedService: SharedService,
              protected snackBar: MatSnackBar, protected routerDataService: RouterDataService, private ws: FtWsService,
              private printService: PrintingService, public defaultValuesService: DefaultValuesService,public paymentService: PaymentService
  ) {
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.viewSettings = _.cloneDeep(data);
      this.handleSettings(data);
    });
  }

  handleSettings(data) {
    if (!_.get(this.viewSettings, 'removeLinearDelete')) {
      this.actions.push({
        class: 'mat-warn',
        icon: 'mdi-delete',
        tooltip: 'shared.delete',
        method: (item, index, ev) => {
          return this.removeDialog(item);
        },
        acl: {resource: _.get(this.viewSettings, 'resource'), action: 'delete', behavior: 'remove'}
      })

    }
    if (_.get(this.viewSettings, 'linearPrint')) {
      this.actions.push({
        class: 'mat-primary',
        icon: 'mdi-printer',
        tooltip: 'shared.print',
        method: (item, index, ev) => {
          return this.handlePrint(item);
        },
        disabled: (item) => {
          return !_.get(item, 'state.is_valid')
        },
        acl: {resource: _.get(this.viewSettings, 'resource'), action: 'validate', behavior: 'disabled'}
      })
    }
    this.viewSettings.viewSettings.viewColumns.push({
      label: '', key: 'actions', type: 'actions', actions: [
        {
          class: 'mat-primary',
          icon: 'mdi-pencil',
          tooltip: 'shared.edit',
          method: (item, index, ev) => {
            return this.handleEdit(item);
          },
          acl: {resource: _.get(this.viewSettings, 'resource'), action: 'get', behavior: 'disabled'}
        }].concat(this.actions)
    });
    let idx = _.findIndex(this.viewSettings.viewSettings.viewColumns, (e) => {
      return _.get(e, 'type') == 'badge'
    });
    if (idx > -1) {
      this.viewSettings.viewSettings.viewColumns[idx].style = (row) => {
        return {'background-color': this.defaultValuesService.getValue(`${_.get(row, 'agreement_state')}_color`)}
      }
    }
    this.settings = _.assignIn(this.settings, data, {});
    if (_.get(this.viewSettings, 'viewNamespace') === 'paymentList') {
      this.subscription = this.ws.observe('payment.handle_refresh').subscribe(data => {
          if (data) this.reloadEvent.emit(true);
        }
      );
    }
    if (_.get(this.viewSettings, 'viewNamespace') === 'patientList') {
      this.subscription = this.ws.observe('patient.handle_refresh').subscribe(data => {
          if (data) this.reloadEvent.emit(true);
        }
      );
    }
  }

  ngOnDestroy() {
    if (_.get(this.viewSettings, 'viewNamespace') === 'paymentList') {
      this.subscription.unsubscribe();
    }
  }

  addClick() {

    this.routerDataService.routerData = null;
    let formRoute = _.get(this.settings, 'formRoute');
    if (_.get(this.settings, 'formRoute')) {
      this.router.navigate([formRoute], {replaceUrl: false});
    } else {
      this.showDialog();
    }
  }

  showDialog(obj?: any) {
    const dialogRef = this.dialog.open(this.settings['formDialog'], _.assign(MEDIUM_DIALOG, {
      data: _.cloneDeep(obj ? obj : null),
      disableClose: true
    }));
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.snackBar.open(this.translateService.instant(_.get(this.settings, 'saveLabel')), null, {
          duration: 1500,
          horizontalPosition: 'left'
        });
        this.reloadEvent.emit(true);
      }
    });
  }

  removeDialog(obj) {
    this.dialog.open(DeleteDialogComponent, _.assign(SMALL_DIALOG, {
      data: {obj: obj, message_text: 'settings.delete_object_warning', deleteTopic: _.get(this.settings, 'deleteTopic')}
    })).afterClosed().subscribe(res => {
      if (res) {
        this.snackBar.open(this.translateService.instant('settings.delete_object_success'), null, {
          duration: 1500,
          horizontalPosition: 'left',
        });
        this.reloadEvent.emit(true);
      }
      else if (res == false) {
        this.dialog.open(AlertDialogComponent, _.assign(SMALL_DIALOG, {data: {message_text: 'settings.delete_object_failed'}}));
      }
    });
  }

  handleEdit(obj) {
    let formRoute = _.get(this.settings, 'formRoute');
    if (formRoute) {
      this.router.navigate([formRoute, obj.id], {replaceUrl: false});
    } else {
      this.showDialog(obj);
    }
  }

  handlePrint(obj) {
    this.printService.performPrint({
      group: _.get(this.viewSettings, 'resource'),
      view: getUrl(_.get(this.viewSettings, 'resource')),
      model: obj.id
    })
  }

  handleFilter(ev) {
    this.initialQuery = _.get(ev, 'value');
  }

}
