import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FtWsService} from '@ft/core';
import {getTopic} from '../../shared/utils/functions';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient, private ws: FtWsService) {
  }

  getUnpaidInvoices(_query){
    return this.ws.call('payment.get_unpaid_invoices', {query:_query});

  }
  getUnpaidDocuments(_query){
    return this.ws.call('payment.get_unpaid_documents', {query:_query});

  }

  handleNotifications(_pk){
    return this.ws.call('payment.handle_documents_notifications', {_pk:_pk});
  }

  getDefaultValue(_key){
    return this.ws.call('payment.get_config', {key:_key});
  }
  handlePaymentFooter(query){
    return  this.ws.call('payment.handle_footer', {query});
  }

}
