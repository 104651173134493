import {Injectable} from '@angular/core';
import {FtWsService} from '@ft/core';
import {HttpClient} from '@angular/common/http';
import * as _ from "lodash";

@Injectable({
  providedIn: 'root'
})
export class DailyWorkflowService {

  constructor(private http: HttpClient, private ws: FtWsService) {
  }


  handleAuthorization(data) {
    return this.ws.call('billing-request.handle_exam_authorization', {_pk: data});
  }

  handleChangeNotification(pk) {
    return this.ws.call('billing-request.handle_details_change_notification', {_pk: pk});
  }

  handleUpdate(data, id){
    return this.http.put(`/api/billing/billing-request/${id}/`, data);
  }
  handleBillingRequest(data){
    return this.http.post(`/api/billing/billing-request/${_.get(data,'id') ? _.get(data,'id') + '/' : ''}`, data);
  }

  handleBillingRequestFooter(query){
    return  this.ws.call('billing-request.handle_footer', {query});
  }
  getBillingRequests(ids){
    return  this.ws.call('billing-request.get_requests', {ids});
  }


}
