import * as _ from 'lodash';

export function getUrl(cls) {
  switch (cls) {
    case 'INV':
      return '/api/billing/invoice/';
    case 'FeesNote':
      return '/api/billing/fees-note/';
    default:
      return `/api/billing/${cls}/`;

  }
}

export function getTopic(cls) {
  switch (cls) {
    case 'INV':
      return 'invoice';
    case 'FeesNote':
      return 'fees-note';
    default:
      return cls;
  }
}

export function getLineDiscount(line) {
  return (!_.isNil(line.discount) && !line.is_comment ) ? (((line.qte || 0.0) * line.price) * line.discount) / 100 : 0.0;
}

export function getLineAmount(line) {
  return line.qte * line.price -getLineDiscount(line);
}
