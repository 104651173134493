import {TableColumnDefinition} from '@ft/table/table/models/table-definitions';
import * as _ from "lodash";

export const BILLING_REQUEST_COLUMNS: TableColumnDefinition[] = [
  {
    'label': 'shared.date',
    'key': 'request_date_time',
    'sortBy': 'request_date_time',
    'type': 'date',
    'is_global_searchable': true
  },
  {
    'label': 'shared.patient_first_name',
    'type': 'text',
    'key': 'patient.first_name',
    'sortBy': 'patient.first_name',
    'is_global_searchable': true,
    'is_ref': true,
    "model": "shared.FtPatient"
  },
  {
    'label': 'shared.patient_last_name',
    'type': 'text',
    'key': 'patient.last_name',
    'sortBy': 'patient.last_name',
    'is_global_searchable': true,
    'is_ref': true,
    "model": "shared.FtPatient"
  },
  {
    "label": "dailyWorkflow.debtor",
    "key": "debtor",
    "type": "badge",
    "is_global_searchable": false,
    callBack:(row)=> _.get(row,'debtor') ?`shared.${row.debtor}`:""
  },

  {
    'label': 'shared.organization',
    'key': 'organization.name',
    'sortBy': 'organization.name',
    'type': 'text',
    'is_ref': true,
    'model': 'shared.insurance.Organization',
    'is_global_searchable': true
  },
    {
    'label': 'shared.doc_date',
    'key': 'doc_date',
    'sortBy': 'doc_date',
    'type': 'date',
    'is_global_searchable': true
  },
  {
    'label': 'shared.payment_status',
    'key': 'payment_status',
    'type': 'boolean',
    'icon': 'mdi-checkbox-blank-circle',
    'tooltip':(row)=>{return `shared.${_.get(row,'payment_status','')}` } ,
    'style': (row) => {
      return _.includes([ 'paid','exempt'],_.get(row, 'payment_status'))  ? {
        'color': '#76FF03',
        'font-size': '20px'
      } : {
        'color': 'grey',
        'font-size': '20px'
      };
    }
  }
];
