<div class="ft-actions" fxFlex="noshrink">
  <ng-template [ftAcl]="{resource: cls, action: 'delete', behavior: 'remove'}">
    <button type="button" *ngIf="model.id && !model?.state?.is_valid" (click)="remove()" mat-mini-fab color="warn"
            [@heightAnimation]>
      <mat-icon fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
    </button>
  </ng-template>
  <button type="button" *ngIf="model.id" mat-mini-fab color="primary"
          (prePrint)="prePrint()" [disabled]="!model?.state?.is_valid"
          [ftPrint]="{group:cls, view:getPrintUrl(),  model:model?.id}">
    <mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
  </button>
  <button type="button" *ngIf="model.id" mat-mini-fab color="primary"
          (prePrint)="prePrint()" [disabled]="!model?.state?.is_valid"
          [ftPrint]="{group:cls, view:getPrintUrl(),type:'pdf',  model:model?.id}">
    <mat-icon fontSet="mdi" fontIcon="mdi-file-pdf-box"></mat-icon>
  </button>
  <span fxFlex></span>
  <button type="button" mat-raised-button color="warn" (click)="exit()">
    <span>{{'shared.exit'|translate}}</span>
  </button>

  <ng-template [ftAcl]="{resource: cls, action: 'invalidate', behavior: 'remove'}">
    <button type="button" mat-raised-button color="warn" class="has-icon" *ngIf="model.id && model?.state?.is_valid"
            (click)="invalidate()">
      <mat-icon fontSet="mdi" fontIcon="mdi-lock-open-outline"></mat-icon>
      <span>{{'shared.invalidate'|translate}}</span>
    </button>
  </ng-template>
  <ng-template [ftAcl]="{resource: cls, action: 'validate', behavior: 'remove'}">
    <button type="button" mat-raised-button class="has-icon success-button" *ngIf="!model?.state?.is_valid"
            [disabled]="formInvalid ||(model?.lines && model?.lines?.length==0)||(model?.details && model?.details?.length==0)"
            (click)="validate()">
      <mat-icon fontSet="mdi" fontIcon="mdi-lock-outline"></mat-icon>
      <span>{{'shared.validate'|translate}}</span>
    </button>
  </ng-template>
  <ng-template [ftAcl]="{resource: cls, action: 'create', behavior: 'remove'}">
    <button type="button" *ngIf="!model?.state?.is_valid" mat-raised-button color="primary" (click)="save(true)"
            [disabled]="formInvalid">
      <span>{{'shared.save_exit'|translate}}</span>
    </button>
    <button type="button" mat-raised-button class="success-button" *ngIf="saveEmpty && !model?.id && formInvalid"
            (click)="save(true)">
      <span>{{'shared.save_empty_document'|translate}}</span>
    </button>
    <button type="button" *ngIf="!model?.state?.is_valid" mat-raised-button color="primary" (click)="save()"
            [disabled]="formInvalid">
      <span>{{'shared.save'|translate}}</span>
    </button>
  </ng-template>
</div>
