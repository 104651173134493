import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {WebdatarocksComponent} from "../../../webdatarocks/webdatarocks.angular4";
import * as WebDataRocks from 'webdatarocks';
import {LOCALIZATION} from "../../utils/pivot-i18n-en";
import {concat, noop, get, assign, map, isEmpty,chain} from "lodash";
import {pivotTabs} from "../../utils/pivot-toolbar";
import {of, Subscription} from "rxjs";
import {StateService} from "../../services/state-service.service";
import * as moment from "moment";
import {ActivatedRoute} from "@angular/router";
import {Interpolation} from "@angular/compiler";
import {getUrl} from "../../../shared/utils/functions";
import {PrintingService} from "@ft/core";

@Component({
  selector: 'ftb-payment-activity',
  templateUrl: './payment-activity.component.html',
  styleUrls: ['./payment-activity.component.scss']
})
export class PaymentActivityComponent implements OnInit {
  loading: Subscription;
  headers = {
    payment_number: {
      type: "string",
      caption: this.translateService.instant("payment.payment_number")
    },
    payment_date: {
      type: "string",
      caption: this.translateService.instant("states.payment_date")
    },
    payment_mode: {
      type: "string",
      caption: this.translateService.instant("shared.payment_mode")
    },
    doc_number: {
      "type": "string",
      caption: this.translateService.instant("states.doc_number")
    },
    exams_date: {
      type: "string",
      caption: this.translateService.instant("states.exams_date")
    },
    has_invoice: {
      type: "boolean",
      caption: this.translateService.instant('states.has_invoice')
    },
    patient_fullname: {
      "type": "string",
      caption: this.translateService.instant("states.patient_fullname")
    },
    taxed_amount: {
      "type": "number",
      caption: this.translateService.instant("states.taxed_amount")
    },
    paid_amount: {
      "type": "number",
      caption: this.translateService.instant("states.paid_amount")
    },
    remaining_amount: {
      "type": "number",
      caption: this.translateService.instant("states.remaining_amount")
    }

  };
  data = [];
  viewType = null;
  filter: any = {
    from_date: moment(), to_date: moment()
  }
  stateName = null;
  stateLabel = null;
  @ViewChild('pivot') child: WebdatarocksComponent;

  onPivotReady(pivot: WebDataRocks.Pivot): void {
    console.log("[ready] WebdatarocksComponent", this.child);
  }

  onCustomizeCell(cell: WebDataRocks.CellBuilder, data: WebDataRocks.CellData): void {
    if (data.isClassicTotalRow) cell.addClass("fm-total-classic-r");
    if (data.isGrandTotalRow) cell.addClass("fm-grand-total-r");
    if (data.isGrandTotalColumn) cell.addClass("fm-grand-total-c");
  }

  config: WebDataRocks.Report;

  onReportComplete(): void {
    this.child.webDataRocks.off("reportcomplete");
    this.child.webDataRocks.setReport(this.config);
  }

  onToolbarCreated(toolbar: any): void {
    toolbar.getTabs = pivotTabs(this);
  }

  constructor(private printService: PrintingService, protected route: ActivatedRoute, private translateService: TranslateService, private stateService: StateService) {
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.viewType = get(data, 'type');
      this.stateLabel = this.translateService.instant(get(data, 'label'))
      this.stateName = this.translateService.instant(get(data, 'name'))
      this.config = {
        dataSource: {
          'dataSourceType': 'json',
          'data': concat(this.headers, this.data),

        },
        localization: LOCALIZATION,
        "slice": {
          "rows": [

            {
              "uniqueName": "payment_date"
            },
            {
              "uniqueName": "payment_mode",
            },
            {
              "uniqueName": "payment_number"
            },
            {
              "uniqueName": "doc_number"
            },
            {
              "uniqueName": "exams_date"
            },
            {
              "uniqueName": "patient_fullname"
            }
          ],
          "columns": [
            //
            {
              "uniqueName": "Measures"
            }
          ],
          "measures": [

            {
              "uniqueName": "doc_number",
              "format": "integer",
              "formula": "count(\"doc_number\")",
              "caption": this.translateService.instant("states.exams_count")
            },
            {
              "uniqueName": "has_invoice",
              "format": "integer",
              "formula": "count(\"has_invoice\")",
              "caption": this.translateService.instant("states.total_has_invoice")
            },
            {
              "uniqueName": "taxed_amount",
              "format": "currency",
              "formula": "sum(\"taxed_amount\")",
              "caption": this.translateService.instant("states.taxed_amount_sum")
            },
            {
              "uniqueName": "paid_amount",
              "format": "currency",
              "formula": "sum(\"paid_amount\")",
              "caption": this.translateService.instant("states.paid_amount_sum")
            },
            {
              "uniqueName": "remaining_amount",
              "format": "currency",
              "formula": "sum(\"remaining_amount\")",
              "caption": this.translateService.instant("states.remaining_amount_sum")
            }
          ],
          "reportFilters": [{
            "uniqueName": "payment_number"
          }, {
            "uniqueName": "payment_date"
          }, {
            "uniqueName": "payment_mode"
          }, {
            "uniqueName": "exams_date"
          }, {
            "uniqueName": "patient_fullname"
          }],
          "flatOrder": [
            "payment_number",
            "payment_date",
            "payment_mode",
            "doc_number",
            "exams_date",
            "patient_fullname",
            "taxed_amount",
            "paid_amount",
            "remaining_amount"
          ]
        },
        "options": {
          "grid": {
            "type": "compact",
            "showTotals": "on",
            "showFilter": true,
            "showGrandTotals": "on",
            "showHeaders": false,
            "showHierarchies": true,
            "showHierarchyCaptions": true,
            "showReportFiltersArea": true
          },
          "configuratorActive": false,
          "configuratorButton": true,
          "showAggregations": true,
          "showCalculatedValuesButton": true,
          "drillThrough": true,
          "sorting": "on",
          "datePattern": "dd/MM/yyyy",
          "dateTimePattern": "dd/MM/yyyy HH:mm:ss",
          //"showDefaultSlice": true,
          "defaultHierarchySortName": "asc"
        },
        "formats": [
          {
            "name": "",
            "thousandsSeparator": " ",
            "decimalSeparator": ",",
            "decimalPlaces": 2,
            "maxSymbols": 20,
            "nullValue": " ",
            "infinityValue": "-",
            "divideByZeroValue": "-"
          },
          {
            "name": "currency",
            "currencySymbol": " MAD",
            "currencySymbolAlign": "right"
          },
          {
            "name": "integer",
            "decimalPlaces": 0
          }
        ]
      };
      this.loadData();

    });
  }

  loadData() {
    // call backend
    this.loading = this.stateService.getPaymentsState(this.formatFilter()).subscribe(data => {
      this.data = map(data, (e) => {
        e.payment_mode = !isEmpty(e.payment_mode) ? this.translateService.instant(`shared.${e.payment_mode}`) : ""
        return e;
      });
      this.child.webDataRocks.setReport(assign(this.config, {
        dataSource: {
          data: concat(this.headers, this.data)
        }
      }));
    });
  }

  handleQuery(ev) {
    this.loadData();
  }

  exportHandler(fileType) {
    this.child.webDataRocks.exportTo(fileType, {filename: `${this.stateLabel}_${ chain(this.formatFilter()).values().join('_').value()}`}, noop)
  }

  formatFilter() {
    return {
      _from: get(this.filter, 'from_date').format('DD/MM/YYYY'),
      _to: this.viewType == 'PERIODIC' && get(this.filter, 'to_date') ? get(this.filter, 'to_date').format('DD/MM/YYYY') : null
    }
  }

  printHandler() {
    // toDO
    // const context = {title: ("states-export")};
    // const header = `<style>*{font-family: sans-serif}</style>${this.interpolate(_.get(this.printConfig, `${this.stateName}.content`, ""))(context)}`;
    // const pageOrientation = _.get(this.printConfig, `${this.stateName}.pageOrientation`, "portrait");

    // this.pivot.print({pageOrientation, header});

  }
}
